import React from 'react';
import { graphql } from 'gatsby';
import BasicStructure from '../../components/basic-structure/basic-structure';
import { getWpBlock } from '../../components/wp-blocks';
import Sidebar from '../../components/sidebar/sidebar';
import ProjectMenu from '../../components/side-bar-components/project-menu';
import TitleMetaTags from '../../components/title-meta-tags/title-meta-tags';
import ContentContainer from '../../components/content-container/content-container';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Menubar from '../../components/menu/menubar';
import BurgerMenu from '../../components/burgermenu/burgermenu';
import { TextBox } from '../../components/textbox/textbox';
import './plus.css';

interface Props {
  data: {
    wpPage: any;
  };
}

interface Childpages {
  edges: Edge[];
}

interface Edge {
  node: Node;
}

interface Node {
  title: string;
  uri: string;
  menuOrder: number;
}

function Plus({ data }: Props): JSX.Element {
  const page = data.wpPage;
  const pageTitle = page?.title || '';
  const allChildrenNode: Node[] = page?.wpChildren?.nodes;
  const blocks = page?.blocks ?? [];

  return (
    <BasicStructure>
      <Header>
        <Menubar styleClass="main-menu" />
        <BurgerMenu />
      </Header>
      <TitleMetaTags title={pageTitle} />
      <ContentContainer>
        <section className="project-page">
          <h1 className="plus-page-title">{pageTitle}</h1>
          <div className={'plus-project-content'}>
            <div className={'plus-project-latest'}>
              {/* <h2 className={'subcategory-title heading-square--full'}>          
              </h2> */}
              {blocks.length > 0 &&
                blocks.map((block, index) => {
                  const WpBlock = getWpBlock(block.__typename);

                  if (!WpBlock) return null;

                  const blockKey = `page-${page.id}-block-${index}`;

                  return (
                    <div key={blockKey}>
                      <WpBlock block={block} />
                    </div>
                  );
                })}
            </div>
            <div className={'plus-project-list'}>
              {allChildrenNode?.length > 0 &&
                allChildrenNode
                  .sort((a, b) => {
                    return a.menuOrder - b.menuOrder;
                  })
                  .map((node) => {
                    return (
                      <div
                        key={`plus-${node.title}-textbox-wrapper`}
                        className="plus-project-list-item"
                      >
                        <TextBox
                          key={`plus-${node.title}-textbox`}
                          url={node.uri}
                          name={node.title}
                          subtitle={''}
                        />
                      </div>
                    );
                  })}
            </div>
          </div>
        </section>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
}

export const pageQuery = graphql`
  query ($pageId: String!) {
    wpPage(id: { eq: $pageId }) {
      wpChildren {
        nodes {
          uri
          ... on WpPage {
            id
            title
            menuOrder
          }
        }
      }
      blocks {
        __typename
        dynamicContent
        isDynamic
        originalContent
        saveContent
        ...WpCoreImageBlockFragment
        ...WpCoreGalleryBlockFragment

        innerBlocks {
          ...WpCoreImageBlockForGalleryFragment
        }
      }
      title
    }
  }
`;

export default Plus;
